import React, { useState } from 'react';
import Content from './Content';

const Downloader = () => {
  const [videoUrl, setVideoUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const qualityMap = {
    video_sd_0: 'Download 360p',
    video_hd_0: 'Download 720p',
    audio_0: 'Download Audio',
  };

  const handleDownload = () => {
    if (!videoUrl.trim()) {
      alert('Please enter a valid Facebook video URL.');
      return;
    }

    setLoading(true);
    setResult(null);

    const options = {
      method: 'GET',
      headers: {
        'x-rapidapi-key': 'a37266fa01mshef61b21bbc4538dp1e41bejsnd9854265d3ce',
        'x-rapidapi-host': 'social-media-video-downloader.p.rapidapi.com',
      },
    };

    fetch(
      `https://social-media-video-downloader.p.rapidapi.com/smvd/get/facebook?url=${encodeURIComponent(videoUrl)}`,
      options
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setResult(data);
          console.log(data);
        } else {
          alert('Error: Unable to fetch the video. Please check the URL.');
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:', error);
        alert('An error occurred. Please try again later.');
      });
  };

  return (
    <section className="w-full">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full text-center">
        <h2 className="text-4xl font-bold mb-6">Facebook Video Downloader</h2>
        <div className="flex flex-col w-[100%] items-center">
          <div className="flex flex-col w-[100%] gap-4 md:gap-0 md:flex-row items-center mb-4 md:w-[90%]">
            <input
              type="text"
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
              className="flex-grow w-[100%] border border-gray-300 p-2 rounded-l-md focus:outline-none focus:ring focus:ring-black-300"
              placeholder="Enter Facebook Video URL"
            />
            <button
              onClick={handleDownload}
              className="bg-gray-600 text-white px-7 py-3 md:py-4 rounded-lg md:rounded-r-md hover:bg-gray-800 transition whitespace-nowrap inline-block"
            >
              Download
            </button>
          </div>
        </div>
        {loading && (
          <div id="loader" className="text-center my-4">
            <ul className="wave-menu">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        )}
        {result && (
          <div id="result" className="flex flex-col items-center justify-center">
            <div className="flex flex-col md:flex-row items-center text-center gap-4 mb-4">
              {result.picture && (
                <img
                  src={result.picture}
                  alt="Video Thumbnail"
                  className="w-48 h-auto rounded-lg"
                />
              )}
              <h2
                id="videoTitle"
                className="text-lg font-semibold text-gray-800"
              >
                {result.title || 'Untitled'}
              </h2>
            </div>
            <div className="flex flex-wrap gap-2 justify-center">
            {result.links &&
  result.links
    .filter((link) =>
      ['video_sd_0', 'video_hd_0', 'audio_0'].includes(link.quality)
    )
    .map((link, index) => (
      <button
        key={index}
        onClick={() => {
          if (link.link) {
            window.open(link.link, '_blank');
          } else {
            alert(
              `${qualityMap[link.quality] || 'This quality'} is not available.`
            );
          }
        }}
        className="bg-blue-700 text-white px-3 py-2 rounded-lg transition"
      >
        {qualityMap[link.quality]}
      </button>
    ))}

            </div>
          </div>
        )}
      </div>
      <Content />
    </section>
  );
};

export default Downloader;
